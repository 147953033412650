<template>
  <div class="">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-primary">
          <i class="fa fa-plus"></i> Refresh
        </button>
      </div>

      <div class="col-auto">
        <button @click="$router.push({
          name:'terrains-create'
        })" class="btn btn-primary">
          <i class="fa fa-plus"></i> Add terrain
        </button>
      </div>
    </div>
    <br>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
            <!-- <th v-for="date in availableDates" :key="date.value" class="p-3">
              {{ date.label }} ({{ date.value }})
            </th> -->
            <th></th>
            <th>Pack</th>
            <th></th>
          </tr>
      </thead>
      <tbody>
          <tr v-for="terrain in terrains" :key="terrain.id">
            <th></th>
            <th>
              {{
                terrain.fullName
              }}
            </th>
            <th>
              {{ terrain.hours }}
            </th>
            
            <th>
              <button @click="$router.push(
                {
                  name: 'terrains-edit',
                  params: {
                    reference:terrain.reference
                }
                })" class="btn ">

                Editer
              </button>
            </th>
            
          </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() { 
    return {
     
    };
  },
  async mounted() {
    await this.$store.dispatch("terrain/getAll");
    
  },
  computed: {
    ...mapGetters('terrain', {
      terrains: 'getTerrains',
    }),
  },
  methods: {
    
    async refresh() {
      await this.$store.dispatch("terrain/getAll");
     
    }
  }
};
</script>

<style scoped>
.bg-success {
  background: #78ff5d !important;
}
</style>
